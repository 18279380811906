import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    borderRadius: 0,
    colorPrimaryBg: "#fff",
    colorPrimary: "#000",
  },
  components: {
    Button: {
      borderRadius: 4,
    },
    Card: {
      headerHeight: 56,
    },
    Table: {
      headerBg: "#f6f8ff",
      headerColor: "#696e79",
    },
  },
};

export default theme;
