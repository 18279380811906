import { useLayoutEffect, useMemo } from "react";
import Prism from "prismjs";
import styles from "./CodeHighlighting.module.css";

interface CodeHighlightingProps {
  value: string;
  height: number;
}

export function CodeHighlighting(props: CodeHighlightingProps) {
  useLayoutEffect(() => {
    Prism.highlightAll();
  }, [props.value]);

  const value = useMemo(() => {
    try {
      return JSON.stringify(JSON.parse(props.value), null, 4);
    } catch (error) {
      return props.value;
    }
  }, [props.value]);

  return (
    <pre className={styles.root} style={{ maxHeight: props.height }}>
      <code className="language-js">{value}</code>
    </pre>
  );
}
