export async function checkAuthentication(token: string): Promise<boolean> {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API!}/auth`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
      mode: "cors",
      redirect: "follow",
    });

    return response.ok && response.status === 200;
  } catch (error) {
    console.error("Error during authentication:", error);
    return false;
  }
}
