import { Button, Card } from "antd";
import { CodeHighlighting } from "../CodeHighlighting";
import { CloseOutlined } from "@ant-design/icons";
import { theme } from "antd";
import styles from "./CellInspector.module.css";
import { useMemo } from "react";

interface CellInspectorProps {
  value: string;
  onClose: () => void;
  height: number;
}

export function CellInspector(props: CellInspectorProps) {
  const config = theme.useToken();

  const codeHighlightingHeight = useMemo(() => {
    if (!config.token.Card?.headerHeight) {
      throw new Error("Card.headerHeight is not defined in the theme");
    }
    return (
      props.height -
      config.token.Card?.headerHeight -
      2 * config.token.paddingLG
    );
  }, [config.token.Card?.headerHeight, config.token.paddingLG, props.height]);

  return (
    <Card
      title="Cell Inspector"
      className={styles.root}
      bordered={false}
      style={{ height: props.height }}
      extra={
        <Button type="text" onClick={props.onClose}>
          <CloseOutlined />
        </Button>
      }
    >
      <CodeHighlighting
        key={props.value}
        height={codeHighlightingHeight}
        value={props.value}
      />
    </Card>
  );
}
