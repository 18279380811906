import { useBoolean, useSize, useTitle, useUpdate } from "ahooks";
import { Button, Input, Space, message } from "antd";
import { useRef, useState } from "react";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { CodeEditor } from "../CodeEditor";
import { Table } from "../Table";
import styles from "./TableWithEditorPanel.module.css";
import { TABS_PANEL_HEIGHT } from "../../Constants";
import { EditorTab } from "../../etc/TabManager";
import { submitQuery } from "../../etc/submitQuery";
import { CaretRightOutlined } from "@ant-design/icons";
import { CellInspector } from "../CellInspector";

interface TableWithEditorPanelProps {
  onDocumentChanged: (sql: string | null) => void;
  onDocumentTitleChanged: (title: string) => void;
  tab: EditorTab;
}

const RIGHT_PANEL_ID = "rightPanelId";

const TOP_MENU_HEIGHT = 57;

export function TableWithEditorPanel(props: TableWithEditorPanelProps) {
  const [data, setData] = useState<any[] | undefined>([]);
  const [selectedQuery, setStatement] = useState<string>(props.tab.document);
  const topPanelRef = useRef<ImperativePanelHandle>(null);
  const bottomPanelRef = useRef<ImperativePanelHandle>(null);
  const rootSize = useSize(document.getElementById(RIGHT_PANEL_ID));
  const update = useUpdate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, { setTrue: freezeUI, setFalse: unfreezUI }] =
    useBoolean(false);
  useTitle(props.tab.title, { restoreOnUnmount: true });
  const [cellData, setCellData] = useState("");
  const [
    cellInspectorVisible,
    { setFalse: hideCellInspector, setTrue: showCellInspector },
  ] = useBoolean(false);

  // useWhyDidYouUpdate('Table with Editor', props);

  function onRun() {
    freezeUI();
    if (selectedQuery.length > 0) {
      submitQuery(selectedQuery).then(setData).then(unfreezUI);
    } else {
      messageApi.info('Click on query string and "Run"');
    }
  }

  const editorHeight =
    (rootSize?.height || 0) * (topPanelRef.current?.getSize() || 0) * 0.01 -
    TOP_MENU_HEIGHT;

  const tableHeight =
    (rootSize?.height || 0) * (bottomPanelRef.current?.getSize() || 0) * 0.01 -
    TABS_PANEL_HEIGHT;

  return (
    <PanelGroup
      direction="vertical"
      id={RIGHT_PANEL_ID}
      autoSaveId="vertical-persistence"
      onLayout={() => update()}
    >
      <Panel ref={topPanelRef} maxSize={80}>
        {contextHolder}
        <div className={styles.topMenu}>
          <Space
            direction="horizontal"
            align="center"
            size={200}
            style={{ height: TOP_MENU_HEIGHT }}
          >
            <Input
              key={props.tab.title}
              variant="borderless"
              defaultValue={props.tab.title}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  event.currentTarget.blur();
                }
              }}
              onBlur={(event) =>
                props.onDocumentTitleChanged(event.target.value)
              }
            />
            <Button
              onClick={onRun}
              type="primary"
              icon={<CaretRightOutlined />}
              loading={loading}
            >
              Run at cursor
            </Button>
          </Space>
        </div>
        <CodeEditor
          defaultValue={props.tab.document}
          onSelectStatement={setStatement}
          onDocumentChange={props.onDocumentChanged}
          height={editorHeight}
        />
      </Panel>
      <PanelResizeHandle
        style={{
          height: "0",
        }}
      />
      <Panel ref={bottomPanelRef} maxSize={80} className={styles.tableDivider}>
        <PanelGroup direction="horizontal">
          <Panel>
            <Table
              loading={loading}
              data={data}
              width={rootSize?.width || 0}
              height={tableHeight}
              onCellClick={(key: string, value: any) => {
                setCellData(value);
                showCellInspector();
              }}
            />
          </Panel>
          {cellInspectorVisible && (
            <>
              <PanelResizeHandle className={styles.divider} />
              <Panel defaultSize={30} minSize={20} maxSize={50}>
                <CellInspector
                  height={tableHeight || 0}
                  value={cellData}
                  onClose={hideCellInspector}
                />
              </Panel>
            </>
          )}
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
}
