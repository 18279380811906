import { EditorState } from "@codemirror/state";
import { EditorView, basicSetup } from "codemirror";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { placeholder } from "@codemirror/view";
import { getSQLQueryByPosition } from "../../etc/getSQLQueryByPosition";
import { useDebounceEffect } from "ahooks";
import { sqlWithJs } from "../../sqlWithJs";

export interface SqlInputProps {
  defaultValue: string;

  /**
   * Function will be called when user change cursor in editor.
   * Result will be query on empty string.
   */
  onSelectStatement: (query: string) => void;
  onDocumentChange?: (query: string | null) => void;
  height: number;
}

const theme = EditorView.theme({
  "&": {
    backgroundColor: "white",
    fontSize: "12pt",
  },
  ".cm-gutter": {
    backgroundColor: "white",
  },
  "&.ͼ2 .cm-gutters": {
    textAlign: "right",
    border: "none",
    margineTop: "0",
  },
  "&.cm-editor.cm-focused": {
    outline: "none",
  },
  "&.ͼ2 .cm-content": {
    padding: "0",
  },
});

function getSQLString(view: EditorView): string {
  let doc = view.state.doc.toString();
  let [start, end] = getSQLQueryByPosition(
    doc,
    view.state.selection.ranges[0].from
  );
  return doc.slice(start, end).trim();
}

export function CodeEditor(props: SqlInputProps) {
  const [doc, setDoc] = useState(props.defaultValue);
  const cursorPosition = useRef(0);
  const editorRef = useRef<HTMLDivElement>(null);

  // useWhyDidYouUpdate('code editor', props);

  useDebounceEffect(
    () => {
      props.onDocumentChange?.(doc);
    },
    [doc],
    { wait: 1000 }
  );

  const onCursorChange = useCallback(
    (view: EditorView) => {
      let sqlString = getSQLString(view);
      props.onSelectStatement(sqlString);
    },
    [props]
  );

  useLayoutEffect(() => {
    let state = EditorState.create({
      doc: props.defaultValue,
      extensions: [
        basicSetup,
        placeholder("Enter your SQL query here..."),
        sqlWithJs(),
        theme,
        EditorView.domEventObservers({
          click: (_event, view) => {
            cursorPosition.current = view.state.selection.ranges[0].from;
            onCursorChange(view);
          },
          keyup: (_event, view) => {
            cursorPosition.current = view.state.selection.ranges[0].from;
            onCursorChange(view);
          },
        }),
        EditorView.updateListener.of((update) => {
          if (update.docChanged) {
            cursorPosition.current = update.state.selection.ranges[0].from;
            setDoc(update.state.doc.toString());
          }
        }),
      ],
    });

    const editorView = new EditorView({
      state,
      parent: editorRef.current!,
    });

    editorView.focus();

    return () => {
      editorView.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // don't add anything to dependencies!!
  }, []);

  return (
    <div
      style={{
        height: props.height,
        overflow: "auto",
      }}
    >
      <div ref={editorRef} />
    </div>
  );
}
