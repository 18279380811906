import { notification } from 'antd';
import { SqlTask } from './etc/task';

export const TIMEOUT = 700;

function authMiddleware(resp: Response) {
  if (resp.status === 401) {
    // Handle 401 Unauthorized response
    localStorage.removeItem('token');
    window.location.reload();
    return resp;
  } else {
    return resp;
  }
}

export const sendTask = async (
  sql: string,
  signal?: AbortController['signal']
) => {
  return await fetch(`${process.env.REACT_APP_SERVER_API!}/sql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    signal,
    body: sql,
    mode: 'cors',
    redirect: 'follow',
  })
    .then(authMiddleware)
    .then(errorTrackMiddleware)
    .then((resp) => resp.json() as Promise<SqlTask>)
    .catch(showNotification());
};

async function errorTrackMiddleware(resp: Response) {
  if (resp.status >= 500) {
    let message = await resp.text();
    console.error(message);
    let error = {
      message: 'Error',
      description: message,
    };
    throw error;
  } else {
    return resp;
  }
}

function showNotification() {
  return (e: Error & { message: string; description: string }) => {
    console.log(e);
    notification.error({
      ...e,
      duration: 0,
    });
    throw e;
  };
}

export async function getTaskResult<T>(
  task: SqlTask,
  signal?: AbortController['signal']
): Promise<T[]> {
  if (task.data) {
    return transformTextToJsonlnMiddleware<T>(task.data);
  }

  if (task.meta.result_path === null) {
    return [] as T[];
  }

  return fetch(task.meta.result_path!, { signal, mode: 'cors' })
    .then(authMiddleware)
    .then(errorTrackMiddleware)
    .then((resp) => resp.text())
    .then((text) => {
      if (text === '') {
        return [] as T[];
      } else {
        return transformTextToJsonlnMiddleware<T>(text);
      }
    })
    .catch(showNotification());
}

function transformTextToJsonlnMiddleware<T>(text: string): T[] {
  const lines = text.split('\n').filter((line) => line.length > 0);
  const doc = lines.map((doc) => JSON.parse(doc));

  return doc.flat() as T[];
}
