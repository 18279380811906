import { InformationSchema } from "../components/TableTree";

export enum TaskStatus {
  Idle = "Idle",
  Loading = "Loading",
  Completed = "Completed",
  Failed = "Failed",
}

export interface SqlTask {
  result: InformationSchema[];
  uuid: string;
  query: string;
  data: string | null;
  meta: {
    result_path: string | null;
    error_message: string | null;
    status: TaskStatus;
  };
}
