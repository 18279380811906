import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { ForwardedRef, forwardRef } from 'react';

const { Text } = Typography;

interface PaginationProps {
  onPrev(): void;
  onNext(): void;
  currentPage: number;
  disablePrevButton: boolean;
  disableNextButton: boolean;
}

const Pagination = forwardRef(function Pagination(
  props: PaginationProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Space size={'small'} ref={ref} style={{ height: 57 }}>
      <Button
        type="text"
        data-testid="pagination-prev-btn"
        onClick={props.onPrev}
        disabled={props.disablePrevButton}
      >
        <LeftOutlined />
      </Button>
      <Text strong data-testid="page-number">
        {props.currentPage}
      </Text>
      <Button
        type="text"
        data-testid="pagination-next-btn"
        onClick={props.onNext}
        disabled={props.disableNextButton}
      >
        <RightOutlined />
      </Button>
    </Space>
  );
});

export { Pagination };
