type DebugString = string;
type startIndex = number;
type endIndex = number;

export function getSQLQueryByPosition(
  text: string,
  position: number,
): [startIndex, endIndex, DebugString] {
  const sign_of_new_group_head_expr = /^;/i;

  let lines: Array<[startIndex, endIndex, DebugString]> = [];
  let last_stop = 0;

  text.split("").forEach((_char, index, _chars) => {
    const document_end_reached = text.length === index + 1;
    const matchStatementBegin = sign_of_new_group_head_expr.test(
      text.substring(index),
    );

    if (matchStatementBegin || document_end_reached) {
      let head = last_stop;
      let end = index + 1;

      lines.push([head, end, text.substring(head, end)]);

      last_stop = end + 1;
    }
  });

  return (
    lines.find(([head, end]) => {
      return head <= position && position <= end && head !== end;
    }) || [0, 0, ""]
  );
}

/**
 * @deprecated Use getSQLQueryByPosition
 * @param text SQL statement
 * @param position cursor position (we take it from IDE)
 * @returns
 */
export function getSQLQueryByPositionWithNewLineDivider(
  text: string,
  position: number,
): [startIndex, endIndex, DebugString] {
  const sign_of_new_group_head_expr = /^\n+\s*\n+/i;

  let lines: Array<[startIndex, endIndex, DebugString]> = [];
  let last_stop = 0;

  text.split("").forEach((_char, index, _chars) => {
    const document_end_reached = text.length === index + 1;
    const matchStatementBegin = sign_of_new_group_head_expr.test(
      text.substring(index),
    );

    if (matchStatementBegin || document_end_reached) {
      let head = last_stop;
      let end = index + 1;

      lines.push([head, end, text.substring(head, end)]);

      last_stop = end + 1;
    }
  });

  return (
    lines.find(([head, end]) => {
      return head <= position && position <= end && head !== end;
    }) || [0, 0, ""]
  );
}
