import { notification } from "antd";
import { getTaskResult, sendTask } from "../sendSqlRequest";
import { TaskStatus } from "./task";

export async function submitQuery(query: string) {
  try {
    let response = await sendTask(query);
    switch (response.meta?.status) {
      case TaskStatus.Completed:
        return await getTaskResult<Record<string, unknown>>(response);
      case TaskStatus.Failed:
        notification.error({
          message: "Failed to execute query",
          description: response.meta.error_message,
          duration: 0,
        });
        break;
    }
  } catch (e) {
    console.log(e);
  }
}
