import { Empty, Table as AntTable } from "antd";
import styles from "./Table.module.css";

const TABLE_HEADER_HEIGHT = 49;
const MINIMUM_COLUMN_WIDTH = 150;
const DEFAULT_INDEX_COLUMN_WIDTH = 40;

interface DataRecord {
  [key: string]: any;
}

function getColumns(
  data: DataRecord,
  width: number,
  handleCellClick: (key: any, value: any) => void
): Array<{
  dataIndex: string;
  title: string;
  key: string;
  render: (text: any, record: DataRecord) => JSX.Element;
}> {
  let keys = Object.keys(data);

  let columnWidth: number = MINIMUM_COLUMN_WIDTH;
  if (width / keys.length + 1 > MINIMUM_COLUMN_WIDTH) {
    columnWidth = width / (keys.length + 1);
  } else {
    columnWidth = MINIMUM_COLUMN_WIDTH;
  }

  const indexColumn = {
    title: "#",
    dataIndex: "#",
    width: DEFAULT_INDEX_COLUMN_WIDTH,
    fixed: "left",
    align: "right",
    key: "#",
    render: (text: any, record: DataRecord) => (
      <span onDoubleClick={() => handleCellClick("#", text)}>{text}</span>
    ),
  };

  return [
    indexColumn,
    ...keys.sort().map((key) => ({
      title: key,
      dataIndex: key,
      width: columnWidth,
      key: key,
      ellipsis: true,
      render: (text: any, record: DataRecord) => (
        <span onDoubleClick={() => handleCellClick(key, text)}>{text}</span>
      ),
    })),
  ];
}

interface TableProps {
  title?: string;
  data: Record<string, any>[] | undefined;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  showNextButton?: boolean;
  showPrevButton?: boolean;
  width: number;
  height: number;
  offset?: number;
  loading: boolean;
  onCellClick: (key: string, value: any) => void;
}

export function Table(props: TableProps) {
  if (props.data?.length === 0) {
    return (
      <div className={styles.emptyDataIcon}>
        <Empty />
      </div>
    );
  }

  let offset = props.offset || 0;
  let data = props.data?.map((item, index) => ({
    ...item,
    key: window.crypto.randomUUID(),
    "#": index + offset + 1,
  }));

  const columns = getColumns(
    props.data?.[0] || {},
    props.width,
    props.onCellClick
  );

  return (
    <>
      <AntTable
        className={styles.root}
        columns={columns}
        dataSource={data || []}
        sticky
        size="small"
        loading={{
          spinning: props.loading,
          size: "large",
        }}
        pagination={false}
        scroll={{ y: props.height - TABLE_HEADER_HEIGHT }}
      />
    </>
  );
}
