import {
  CloudOutlined,
  DatabaseOutlined,
  TableOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Skeleton, Tree } from "antd";
import { useMemo } from "react";
import {
  catalogsState,
  getCatalogs,
  getSchemas,
  getTables,
  getColumns,
} from "../../DataContext";
import { useRecoilValue } from "recoil";

export type InformationSchema = {
  table_catalog: string;
  table_schema: string;
  table_name: string;
  table_type: Uppercase<'BASE TABLE' | 'VIEW'>;
  column_name: string;
  data_type: string;
};

interface TableProps {
  onSelectTable: (catalog: string) => void;
}

export function TableTree(props: TableProps) {
  const data = useRecoilValue(catalogsState);

  const treeData = useMemo(() => {
    if (data.length > 0) {
      return getCatalogs(data)
        .sort((a, b) => a.localeCompare(b))
        .map((catalog) => {
          return {
            title: catalog,
            key: `${catalog}`,
            icon: <CloudOutlined />,
            children: getSchemas(data, catalog)
              .sort((a, b) => a.localeCompare(b))
              .map((schema) => {
                return {
                  title: schema,
                  key: `${catalog}.${schema}`,
                  icon: <DatabaseOutlined />,
                  selectable: false,
                  children: getTables(data, catalog, schema)
                    .sort((a, b) => a.localeCompare(b))
                    .map((table) => ({
                      key: `SQL:'${catalog}'.'${schema}'.'${table}'`,
                      title: table,
                      isLeaf: false,
                      icon: <TableOutlined />,
                      selectable: true,
                      children: getColumns(data, catalog, schema, table)
                        .sort((a, b) =>
                          a.column_name.localeCompare(b.column_name)
                        )
                        .map((column) => ({
                          key: `${catalog}.${schema}.${table}.${column.column_name}`,
                          title: `${column.column_name} (${column.data_type})`,
                          isLeaf: true,
                          icon: <TagOutlined />,
                          selectable: false,
                        })),
                    })),
                };
              }),
          };
        });
    }
  }, [data]);

  return (
    <>
      {!treeData ? (
        <Skeleton active />
      ) : (
        <Tree
          showIcon
          treeData={treeData}
          expandAction="click"
          onSelect={([key, _]) => props.onSelectTable(key as string)}
        />
      )}
    </>
  );
}
