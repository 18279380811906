import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useCounter, usePrevious } from "ahooks";
import {
  App as AntApp,
  Button,
  Card,
  Flex,
  Layout,
  Skeleton,
  Space,
} from "antd";
import { useEffect, useMemo, useRef } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { TABS_PANEL_HEIGHT } from "./Constants";
import { Tab } from "./components/Tab";
import { TablePanel } from "./components/TablePanel";
import { TableTree } from "./components/TableTree";
import { TableWithEditorPanel } from "./components/TableWithEditorPanel";
import { EditorTab, TableTab } from "./etc/TabManager";
import { useTabManager } from "./hooks/useTabManager";
import { statusState, useInitializeCatalogs } from "./DataContext";
import { useRecoilValue } from "recoil";
import { TaskStatus } from "./etc/task";

function App() {
  const {
    tabs,
    activeTab,
    activeTabId,
    createTableTab,
    createEditorTab,
    deleteTab,
    deleteAllTabs,
    deleteRightTabs,
    deleteOtherTabs,
    setActiveTab,
    updateDocumentQuery,
    updateDocumentOffset,
    updateDocumentTitle,
  } = useTabManager();
  const [reloadTableList, { inc: setReloadTableList }] = useCounter(0);
  const loadingStatus = useRecoilValue(statusState);
  const previousTabsCount = usePrevious(tabs.length);
  const initCatalogs = useInitializeCatalogs();

  useEffect(() => {
    initCatalogs();
  }, []);

  useEffect(() => {
    if (previousTabsCount && tabs.length > previousTabsCount) {
      tabsContainerRef.current?.scrollTo({
        left:
          (document.querySelector("[data-last=true]") as HTMLElement)
            ?.offsetLeft || 0,
      });
    }
  }, [tabs.length, previousTabsCount]);

  const tabsContainerRef = useRef<HTMLDivElement>(null);

  async function onSelectTable(tableName: string) {
    if (tableName?.includes("SQL:")) {
      const newTableName = tableName.replace("SQL:", "");
      createTableTab(newTableName);
    }
  }

  async function handleReload() {
    try {
      await initCatalogs();
      setReloadTableList();
    } catch (error) {
      console.error("Failed to reload table list:", error);
    }
  }

  const tabsToRender = useMemo(() => {
    return tabs.map((tab, index, allTabs) => (
      <Tab
        active={activeTabId === tab.id}
        key={tab.id}
        onClose={() => deleteTab(tab.id)}
        onCloseAll={deleteAllTabs}
        onCloseRight={() => deleteRightTabs(tab.id)}
        onCloseOther={() => deleteOtherTabs(tab.id)}
        onSelect={() => setActiveTab(tab.id)}
        type={tab.type}
        isLast={allTabs.at(-1)?.id === tab.id}
      >
        {tab.title}
      </Tab>
    ));
  }, [
    activeTabId,
    deleteTab,
    deleteAllTabs,
    deleteRightTabs,
    deleteOtherTabs,
    setActiveTab,
    tabs,
  ]);

  return (
    <AntApp>
      <Layout style={{ height: "100vh", backgroundColor: "#fff" }}>
        <PanelGroup direction="horizontal" autoSaveId="horizontal-persistence">
          <Panel defaultSize={20} minSize={10} maxSize={50}>
            <Card
              style={{
                height: '100vh',
                overflow: 'auto',
              }}
              title="NYK | Dataspace"
              extra={
                <Button
                  type="link"
                  onClick={handleReload}
                  loading={loadingStatus === TaskStatus.Loading}
                  icon={<ReloadOutlined />}
                ></Button>
              }
            >
              {loadingStatus === TaskStatus.Loading ? (
                <Skeleton active />
              ) : (
                <TableTree
                  key={String(reloadTableList)}
                  onSelectTable={onSelectTable}
                />
              )}
            </Card>
          </Panel>
          <PanelResizeHandle
            style={{
              width: "0",
            }}
          />
          <Panel>
            <Flex
              ref={tabsContainerRef}
              wrap="nowrap"
              style={{
                height: TABS_PANEL_HEIGHT,
                overflowX: "auto",
                overflowY: "hidden",
                scrollbarWidth: "none",
                borderBottom: "1px solid #e8e8e8",
              }}
            >
              {tabsToRender}
              <Tab onSelect={() => createEditorTab("")}>
                <Space>
                  <PlusOutlined />
                </Space>
              </Tab>
            </Flex>
            {activeTab?.type === "editor" && (
              <TableWithEditorPanel
                key={activeTab.id}
                onDocumentChanged={(doc) =>
                  updateDocumentQuery(activeTab.id!, doc)
                }
                tab={activeTab as EditorTab}
                onDocumentTitleChanged={(title) =>
                  updateDocumentTitle(activeTab.id!, title)
                }
              />
            )}
            {activeTab?.type === "table" && (
              <TablePanel
                key={activeTabId}
                tab={activeTab as TableTab}
                onCreateQuery={createEditorTab}
                onSetOffset={(value) => {
                  updateDocumentOffset(activeTabId!, value);
                }}
              />
            )}
          </Panel>
        </PanelGroup>
      </Layout>
    </AntApp>
  );
}

export default App;
