import {
  ConsoleSqlOutlined,
  TableOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Dropdown, Flex, Space, Typography } from "antd";
import { PropsWithChildren, useMemo } from "react";
import { TabType } from "../../etc/TabManager";
import styles from "./Tab.module.css";
import { useCreation } from "ahooks";
const { Text } = Typography;

interface TabProps extends PropsWithChildren {
  onSelect: () => void;
  onClose?: () => void;
  onCloseAll?: () => void;
  onCloseRight?: () => void;
  onCloseOther?: () => void;
  active?: boolean;
  type?: TabType;
  isLast?: boolean;
}

export function Tab(props: TabProps) {
  const items = useCreation(() => {
    const actions = [
      {
        label: "Close",
        key: "close",
        action: props.onClose,
      },
      {
        label: "Close all",
        key: "closeAll",
        action: props.onCloseAll,
      },
      {
        label: "Close all to the right",
        key: "closeAllRight",
        action: props.onCloseRight,
      },
      {
        label: "Close other tabs",
        key: "closeOther",
        action: props.onCloseOther,
      },
    ];

    return actions
      .filter((item) => item.action)
      .map((item) => ({
        label: item.label,
        key: item.key,
        onClick: item.action,
      }));
  }, [props.onClose, props.onCloseAll, props.onCloseRight]);

  let icon = useMemo(() => {
    if (props.type) {
      return props.type === "editor" ? (
        <ConsoleSqlOutlined />
      ) : (
        <TableOutlined />
      );
    } else {
      return <></>;
    }
  }, [props.type]);

  return (
    <Dropdown menu={{ items }} trigger={["contextMenu"]}>
      <Flex
        className={`${styles.root} ${props.active ? styles.active : ""}`}
        align="center"
        justify="center"
        wrap="nowrap"
        gap={"middle"}
        onClick={props.onSelect}
        data-last={props.isLast}
      >
        <Space>
          {icon}
          <Text ellipsis className={styles.tabText}>
            {props.children}
          </Text>
        </Space>

        {props.onClose && (
          <div className={styles.closeBtn}>
            <CloseCircleFilled
              style={{ fontSize: 16 }}
              onClick={() => {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
        )}
      </Flex>
    </Dropdown>
  );
}
