import { Button, Space, Spin, Typography } from "antd";
import { TablePagination } from "../TablePagination";
import { LIMIT, PER_PAGE, TABS_PANEL_HEIGHT } from "../../Constants";
import { Table } from "../Table";
import { useMemo, useRef, useState } from "react";
import { useBoolean, useDebounceEffect, useSize, useTitle } from "ahooks";
import { TableTab } from "../../etc/TabManager";
import { submitQuery } from "../../etc/submitQuery";
import { ReloadOutlined } from "@ant-design/icons";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import styles from "./TablePanel.module.css";
import { CellInspector } from "../CellInspector";

const { Text } = Typography;

interface TablePanelProps {
  onCreateQuery(query: string): void;
  onSetOffset(value: number): void;
  tab: TableTab;
}
export function TablePanel(props: TablePanelProps) {
  const root = useRef<HTMLDivElement>(null);
  const topMenu = useRef<HTMLDivElement>(null);
  const pagination = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[] | undefined>([]);
  useTitle(props.tab.title, { restoreOnUnmount: true });
  const componentSize = useSize(root);
  const menuSize = useSize(topMenu);
  const paginationSize = useSize(pagination);
  const [loading, { setTrue: freezeUI, setFalse: unfreezUI }] =
    useBoolean(true);
  const [cellData, setCellData] = useState("");
  const [
    cellInspectorVisible,
    { setFalse: hideCellInspector, setTrue: showCellInspector },
  ] = useBoolean(false);

  function loadData() {
    freezeUI();
    submitQuery(generateSql(props.tab.tableName, props.tab.offset))
      .then(setData)
      .then(unfreezUI);
  }

  useDebounceEffect(
    () => {
      loadData();
    },
    [props.tab.offset, props.tab.tableName],
    { wait: 1000 }
  );

  const dataForTablePanel = useMemo(() => {
    if (data && data.length >= LIMIT) {
      return data.slice(0, PER_PAGE);
    } else {
      return data;
    }
  }, [data]);

  const tableHeight =
    componentSize?.height! -
    paginationSize?.height! -
    menuSize?.height! -
    TABS_PANEL_HEIGHT;
  const displayTableName = props.tab.title?.replace(/['"]/g, "");

  function handleCreateQuery() {
    props.onCreateQuery(generateSql(props.tab.tableName, props.tab.offset));
  }

  function handleReload() {
    loadData();
  }

  return (
    <div ref={root} style={{ height: "100%" }}>
      <Space
        align="baseline"
        ref={topMenu}
        size={200}
        style={{ padding: "12px 15px" }}
      >
        <Text strong>{displayTableName}</Text>
        <Button onClick={handleCreateQuery} type="primary" disabled={loading}>
          Create Query
        </Button>
        <Button
          type="link"
          onClick={handleReload}
          loading={loading}
          icon={<ReloadOutlined />}
        ></Button>
      </Space>

      <PanelGroup direction={"horizontal"}>
        <Panel>
          <Spin spinning={loading} size="large">
            <Table
              data={dataForTablePanel}
              width={componentSize?.width || 0}
              height={tableHeight || 0}
              offset={props.tab.offset}
              loading={false}
              onCellClick={(key: string, value: any) => {
                setCellData(value);
                showCellInspector();
              }}
            />
            <div style={{ borderTop: "1px solid #f0f0f0" }}>
              <TablePagination
                ref={pagination}
                showPerPage={LIMIT - 1}
                itemsCount={data?.length || 0}
                currentOffset={props.tab.offset}
                onPrev={props.onSetOffset}
                onNext={props.onSetOffset}
              />
            </div>
          </Spin>
        </Panel>
        {cellInspectorVisible && (
          <>
            <PanelResizeHandle className={styles.divider} />
            <Panel defaultSize={30} minSize={20} maxSize={50}>
              <CellInspector
                height={tableHeight + (paginationSize?.height || 0)}
                value={cellData}
                onClose={hideCellInspector}
              />
            </Panel>
          </>
        )}
      </PanelGroup>
    </div>
  );
}

function generateSql(tableName: string, offset: number) {
  return `SELECT * FROM ${tableName} OFFSET ${offset} LIMIT 51;`;
}
