import { randomUUID } from "crypto";

export type ID = string;

export interface Tab {
  id: ID;
  type: TabType;
  title: string;
}

export interface EditorTab extends Tab {
  type: "editor";
  document: string;
}

export interface TableTab extends Tab {
  type: "table";
  tableName: string;
  offset: number;
}

export type TabType = "editor" | "table";

export interface TabManagerState {
  activeTabId: ID | null;
  tabs: Array<Tab>;
}

const DEFAULT_UNTITLED_STRING = "Untitled query";

export class TabManager {
  private tabs: Array<Tab> = [];
  private activeTabId: ID | null = null;

  public getTabs() {
    return this.tabs;
  }

  private generateId(): ID {
    return globalThis.crypto?.randomUUID?.() || randomUUID();
  }

  public persistToStorage() {
    localStorage.setItem("tabManager", JSON.stringify(this.getState()));
  }

  public static loadFromStorage() {
    const state = localStorage.getItem("tabManager");
    if (state) {
      return TabManager.fromState(JSON.parse(state));
    } else {
      return new TabManager();
    }
  }

  updateDocumentTitle(id: ID, title: string) {
    let tab = this.tabs.find((tab) => tab.id === id);
    if (tab) {
      tab.title = title;
    } else {
      throw new Error("Tab not found");
    }
  }

  public getActiveTab(): Tab | undefined {
    return this.tabs.find((tab) => tab.id === this.activeTabId);
  }

  public updateDocumentOffset(tabId: ID, offset: number) {
    const tab = this.tabs.find((tab) => tab.id === tabId);
    if (tab?.type === "table") {
      (tab as TableTab).offset = offset;
    } else {
      throw new Error("Tab is not a table");
    }
  }

  public updateDocumentQuery(tabId: ID, document: string | null) {
    const tab = this.tabs.find((tab) => tab.id === tabId);
    if (tab) {
      (tab as EditorTab).document = document || "";
    } else {
      throw new Error("Tab not found");
    }
  }

  public static fromState(state: TabManagerState) {
    const tabManager = new TabManager();
    tabManager.tabs = state.tabs;
    tabManager.activeTabId = state.activeTabId;
    return tabManager;
  }

  public getState(): TabManagerState {
    return {
      tabs: this.tabs,
      activeTabId: this.activeTabId,
    };
  }

  public createEditorTab(document: string) {
    const equalDocument = this.tabs
      .filter((tab) => tab.type === "editor")
      .map<EditorTab>((v) => v as EditorTab)
      .find((tab) => tab.document === document);

    if (equalDocument) {
      this.setActiveTab(equalDocument.id);
      return equalDocument;
    } else {
      const newTab = {
        id: this.generateId(),
        title: DEFAULT_UNTITLED_STRING,
        type: "editor",
        document: document,
      } as EditorTab;

      this.tabs.push(newTab);

      this.setActiveTab(newTab.id);

      return newTab;
    }
  }

  public createTableTab(tableName: string) {
    function predicate(tab: TableTab) {
      return tab.tableName === tableName;
    }

    const tabWithSameTableName = this.tabs
      .filter((tab) => tab.type === "table")
      .map((v) => v as TableTab)
      .find(predicate);

    if (tabWithSameTableName) {
      this.activeTabId = tabWithSameTableName.id;
      return tabWithSameTableName;
    } else {
      const newTab = {
        id: this.generateId(),
        title: tableName.replaceAll("'", ""),
        type: "table",
        offset: 0,
        tableName,
      } as TableTab;

      this.tabs.push(newTab);

      this.activeTabId = newTab.id;
      return newTab;
    }
  }

  public setActiveTab(tabId: ID) {
    this.activeTabId = tabId;
    return this.getActiveTab();
  }

  public deleteTab(id: ID) {
    let index = this.tabs.findIndex((tab) => tab.id === id);

    if (this.activeTabId === id) {
      if (this.tabs[index + 1]) {
        this.activeTabId = this.tabs[index + 1].id;
      } else if (this.tabs[index - 1]) {
        this.activeTabId = this.tabs[index - 1]?.id || null;
      }
    }

    this.tabs = this.tabs.filter((tab) => tab.id !== id);
  }

  public deleteAllTabs() {
    this.tabs = [];
    this.activeTabId = null;
  }

  public deleteRightTabs(id: ID) {
    let activeIndex = this.tabs.findIndex((tab) => tab.id === id);

    if (activeIndex !== -1) {
      this.tabs = this.tabs.slice(0, activeIndex + 1);
    }
  }

  public deleteOtherTabs(id: ID) {
    this.tabs = this.tabs.filter((tab) => tab.id === id);
  }
}
