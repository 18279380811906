import { ID } from "../etc/TabManager";
import { useEventListener, useUpdate } from "ahooks";

export function useTabManager() {
  const update = useUpdate();

  useEventListener(
    "beforeunload",
    () => {
      window.tabManager.persistToStorage();
    },
    {
      target: window,
      passive: false,
      capture: true,
    }
  );

  return {
    tabs: window.tabManager.getTabs(),
    activeTabId: window.tabManager.getActiveTab()?.id,
    activeTab: window.tabManager.getActiveTab(),

    createTableTab: (tableName: string) => {
      window.tabManager.createTableTab(tableName);
      update();
    },
    createEditorTab: (query: string) => {
      window.tabManager.createEditorTab(query);
      update();
    },
    deleteTab: (tabId: ID) => {
      window.tabManager.deleteTab(tabId);
      update();
    },

    deleteAllTabs: () => {
      window.tabManager.deleteAllTabs();
      update();
    },

    deleteRightTabs: (tabId: ID) => {
      window.tabManager.deleteRightTabs(tabId);
      update();
    },

    deleteOtherTabs: (tabId: ID) => {
      window.tabManager.deleteOtherTabs(tabId);
      update();
    },

    setActiveTab: (tabId: ID) => {
      window.tabManager.setActiveTab(tabId);
      update();
    },
    updateDocumentQuery: (tabId: ID, document: string | null) => {
      window.tabManager.updateDocumentQuery(tabId, document);
      update();
    },
    updateDocumentOffset: (tabId: ID, offset: number) => {
      window.tabManager.updateDocumentOffset(tabId, offset);
      update();
    },
    updateDocumentTitle: (tabId: ID, title: string) => {
      window.tabManager.updateDocumentTitle(tabId, title);
      update();
    },
  };
}
