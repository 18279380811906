import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import { AuthForm } from "./components/AuthForm";
import { ConfigProvider } from "antd";
import theme from "./theme";
import { TabManager } from "./etc/TabManager";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare global {
  var tabManager: TabManager;
  function randomUUID(): string;
}

window.tabManager = TabManager.loadFromStorage();

root.render(
  // <React.StrictMode>
  <ErrorBoundary
    onError={localStorage.clear}
    onReset={localStorage.clear}
    fallback={<>Error happened. Resetting settings...</>}
  >
    <RecoilRoot>
      <ConfigProvider theme={theme}>
        <AuthForm>
          <App />
        </AuthForm>
      </ConfigProvider>
    </RecoilRoot>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
