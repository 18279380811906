import {
  atom,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilState,
} from "recoil";
import { InformationSchema } from "./components/TableTree";
import { getTaskResult, sendTask } from "./sendSqlRequest";
import { TaskStatus } from "./etc/task";

export const catalogsState = atom<InformationSchema[]>({
  key: "catalogsState",
  default: [],
});

export const statusState = atom<TaskStatus>({
  key: "statusState",
  default: TaskStatus.Idle,
});

export function useInitializeCatalogs() {
  const [, setCatalogs] = useRecoilState(catalogsState);
  const [, setStatus] = useRecoilState(statusState);
  const sqlQuery =
    "select table_catalog, table_schema, table_name, column_name, data_type from information_schema.columns;";

  const fetchCatalogs = async () => {
    try {
      setStatus(TaskStatus.Loading);
      const task = await sendTask(sqlQuery);
      setStatus(task.meta.status);

      if (task.meta.status === TaskStatus.Completed) {
        const result = await getTaskResult<InformationSchema>(task);
        setCatalogs(result);
      }
    } catch (e) {
      setStatus(TaskStatus.Failed);
    }
  };
  return fetchCatalogs;
}

export const mergedStateSelector = selector({
  key: "mergedStateSelector",
  get: ({ get }) => {
    const catalogs = get(catalogsState);
    const status = get(statusState);
    return {
      catalogs,
      status,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(catalogsState, newValue.catalogs);
      set(statusState, newValue.status);
    }
  },
});

export const catalogsSelector = selector<string[]>({
  key: "catalogsSelector",
  get: ({ get }) => {
    const data = get(catalogsState);
    return getCatalogs(data);
  },
});

export const schemasSelector = selectorFamily<string[], string>({
  key: "schemasSelector",
  get:
    (catalog: string) =>
    ({ get }) => {
      const data = get(catalogsState);
      return getSchemas(data, catalog);
    },
});

export const tablesSelector = selectorFamily<
  string[],
  { catalog: string; schema: string }
>({
  key: "tablesSelector",
  get:
    ({ catalog, schema }) =>
    ({ get }) => {
      const data = get(catalogsState);
      return getTables(data, catalog, schema);
    },
});

export const columnsSelector = selectorFamily<
  Array<Pick<InformationSchema, "column_name" | "data_type">>,
  { catalog: string; schema: string; table: string }
>({
  key: "columnsSelector",
  get:
    ({ catalog, schema, table }) =>
    ({ get }) => {
      const data = get(catalogsState);
      return getColumns(data, catalog, schema, table);
    },
});

export function getCatalogs(
  data: InformationSchema[]
): InformationSchema["table_schema"][] {
  return data.reduce((acc, row) => {
    if (acc.includes(row.table_catalog)) {
      return acc;
    } else {
      return [...acc, row.table_catalog];
    }
  }, [] as string[]);
}

export function getSchemas(
  data: InformationSchema[],
  catalog: InformationSchema["table_catalog"]
): Array<InformationSchema["table_schema"]> {
  return Array.from(
    data
      .filter((record) => record.table_catalog === catalog)
      .reduce((acc, record) => {
        acc.add(record.table_schema);
        return acc;
      }, new Set<InformationSchema["table_schema"]>())
      .values()
  );
}

export function getTables(
  data: InformationSchema[],
  catalog: InformationSchema["table_catalog"],
  schema: InformationSchema["table_schema"]
): Array<InformationSchema["table_schema"]> {
  return Array.from(
    data
      .filter(
        (record) =>
          record.table_catalog === catalog && record.table_schema === schema
      )
      .reduce((acc, record) => {
        acc.add(record.table_name);
        return acc;
      }, new Set<InformationSchema["table_schema"]>())
      .values()
  );
}

export function getColumns(
  data: InformationSchema[],
  catalog: InformationSchema["table_catalog"],
  schema: InformationSchema["table_schema"],
  table: InformationSchema["table_name"]
): Array<Pick<InformationSchema, "column_name" | "data_type">> {
  return Array.from(
    data
      .filter(
        (record) =>
          record.table_catalog === catalog &&
          record.table_schema === schema &&
          record.table_name === table
      )
      .reduce((acc, record) => {
        acc.add(record);
        return acc;
      }, new Set<Pick<InformationSchema, "column_name" | "data_type">>())
      .values()
  );
}
