import React from 'react';
import { ReactComponent as LogoSvg } from './Logo.svg';

interface LogoProps {
  height: number;
}

export const Logo: React.FC<LogoProps> = ({ height }) => {
  return <LogoSvg height={height} />;
};
