import { grey } from '@ant-design/colors';
import {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Logo } from '../Logo';
import { Col, Layout, Row, Spin } from 'antd';
import { checkAuthentication } from './checkAuthentication';

declare global {
  interface Window {
    google: any;
  }
}

function initializeGoogleSignIn(callback: Function, tag: HTMLDivElement) {
  if (window.google?.accounts) {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      cancel_on_tap_outside: false,
      callback,
    });
    window.google.accounts.id.renderButton(tag, {
      type: 'outline',
      theme: 'outline',
      size: 'large',
    });
  }
}

type AuthState = 'NOT_AUTHORIZED_LOADING' | 'AUTHORIZED' | 'NOT_AUTHORIZED';

export function AuthForm(props: PropsWithChildren) {
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const [authState, setAuthState] = useState<AuthState>(
    'NOT_AUTHORIZED_LOADING'
  );

  useLayoutEffect(() => {
    if (authState === 'NOT_AUTHORIZED') {
      // If authentication fails or token is invalid, show the Google button
      if (googleButtonRef.current) {
        initializeGoogleSignIn(handleGoogleSignIn, googleButtonRef.current);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (authState === 'AUTHORIZED') {
      // Nothing to do
    }
  }, [authState]);

  useEffect(() => {
    if (authState === 'NOT_AUTHORIZED_LOADING') {
      const token = localStorage.getItem('token');
      checkAuthentication(token || '')
        .then((isAuthenticated) => {
          setAuthState(isAuthenticated ? 'AUTHORIZED' : 'NOT_AUTHORIZED');
        })
        .catch(() => {
          setAuthState('NOT_AUTHORIZED');
        });
    }
  }, [authState]);

  const handleGoogleSignIn = async (response: { credential: string }) => {
    const { credential } = response;
    if (credential) {
      localStorage.setItem('token', credential);
      window.location.reload();
    }
  };

  switch (authState) {
    case 'NOT_AUTHORIZED_LOADING':
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Row justify="center" style={{ height: '100vh' }} align="middle">
            <Col
              style={{
                borderRight: `1px solid ${grey[0]}`,
                marginRight: '30px',
              }}
            >
              <Logo height={200} />
            </Col>
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        </Layout>
      );
    case 'AUTHORIZED':
      return <>{props.children}</>;
    case 'NOT_AUTHORIZED':
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Row justify="center" style={{ height: '100vh' }} align="middle">
            <Col
              style={{
                borderRight: `1px solid ${grey[0]}`,
                marginRight: '30px',
              }}
            >
              <Logo height={200} />
            </Col>
            <Col>
              <div ref={googleButtonRef} />
            </Col>
          </Row>
        </Layout>
      );
  }
}
