import { LRLanguage, LanguageSupport } from "@codemirror/language";
import { parser as jsParser } from "@lezer/javascript";
import { javascript } from "@codemirror/lang-javascript";
import { parseMixed } from "@lezer/common";
import { SQLDialect, sql } from "@codemirror/lang-sql";
import { SQLKeywords, SQLTypes } from "./Constants";

const DataspaceSql = SQLDialect.define({
  keywords: SQLKeywords + "js",
  types: SQLTypes + "any number",
});

export const sqlWithCustom = sql({ dialect: DataspaceSql });

const mixedSqlParser = DataspaceSql.language.parser.configure({
  wrap: parseMixed((node) => {
    console.log(node);
    return node.name === "js" || node.name === "end"
      ? { parser: jsParser }
      : null;
  }),
});

export const mixedSql = LRLanguage.define({
  parser: mixedSqlParser,
});

export function sqlWithJs() {
  return new LanguageSupport(sqlWithCustom.language, [
    javascript().support,
    sqlWithCustom,
  ]);
}
