import { ForwardedRef, forwardRef } from 'react';
import { Pagination } from '../Pagination';
import { Flex } from 'antd';

interface TablePaginationProps {
  showPerPage: number;
  itemsCount: number;
  currentOffset: number;

  onPrev(prevOffset: number): void;
  onNext(nextOffset: number): void;
}

const TablePagination = forwardRef(function TablePagination(
  {
    showPerPage,
    itemsCount,
    currentOffset,
    onPrev,
    onNext,
  }: TablePaginationProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const handlePrevClick = () => {
    const prevOffset = currentOffset - showPerPage;
    onPrev(prevOffset);
  };

  const handleNextClick = () => {
    const nextOffset = currentOffset + showPerPage;
    onNext(nextOffset);
  };

  const currentPage = Math.ceil(currentOffset / showPerPage) + 1;
  const disableNextButton = !(itemsCount > showPerPage);

  return (
    <Flex justify="center" align="center">
      <Pagination
        ref={ref}
        onPrev={handlePrevClick}
        onNext={handleNextClick}
        currentPage={currentPage}
        disablePrevButton={currentPage === 1}
        disableNextButton={disableNextButton}
      />
    </Flex>
  );
});

export { TablePagination };
